import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import NavBar from './NavBar';
import Loader from 'components/Loader';
import SideMenu from 'components/SideMenu';

import './index.css'
import './layout.module.scss'

console.log('%c hi fwends', 'font-size:2rem; color: #006600');
console.log('%c excited to see you', 'font-size:1rem');
console.log('%c don\'t forget to bring a towel', 'font-size:1rem');

class LayoutContainer extends React.Component {
  componentDidMount() {
    this.setupAnalytics();
  }

  state = { loading: false, sideMenuOpen: false }

  bodyClick = () => {
    if(this.state.sideMenuOpen) {
      this.toggleSideMenu();
    }
  }

  toggleLoader = (override) => {
    this.setState(
      ({ loading }) => ({ loading: override || !loading })
    );
  }

  toggleSideMenu = () => {
    this.setState(
      ({ sideMenuOpen }) => ({ sideMenuOpen: !sideMenuOpen })
    );
  }
  
  setupAnalytics = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'UA-111663576-2');
  }

  render () {
    const { children, data } = this.props;
    return (
      <React.Fragment>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: data.site.siteMetadata.description },
            { name: 'keywords', content: data.site.siteMetadata.keywords },
            { name: 'viewport', content: "width=device-width, initial-scale=1.0, viewport-fit=cover"},
            { name: 'robots', content: 'noindex' }
          ]}
          script={[
            { type: 'text/javascript', src: 'https://www.googletagmanager.com/gtag/js?id=UA-111663576-2', async: true },
            { type: 'text/javascript', src: 'https://addevent.com/libs/atc/1.6.1/atc.min.js', async: true, defer: true },
          ]}
        >
          <html lang="en" />
        </Helmet>
        <NavBar siteTitle={data.site.siteMetadata.title} toggleSideMenu={this.toggleSideMenu}/>
        <main onClick={this.bodyClick}>
          { React.Children.map(children, (child) => React.cloneElement(child, { toggleLoader: this.toggleLoader })) }
        </main>
        <SideMenu isOpen={this.state.sideMenuOpen} toggleSideMenu={this.toggleSideMenu} />
        { this.state.loading && <Loader /> }
      </React.Fragment>
    );
  }
}

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            description
            keywords
          }
        }
      }
    `}
    render={data => <LayoutContainer children={children} data={data} /> }
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
