import React from 'react'
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import classnames from 'classnames';
import styles from './side-menu.module.scss';

import NavLinks from 'components/NavBar/navLinks';

const WindmillIcon = (props) =>
  <StaticQuery
    query={graphql`
      query {
        windmill: file(relativePath: { eq: "windmill.png" }) {
          childImageSharp {
            fluid(maxWidth: 40) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data =>
      <div className={styles.iconContainer} {...props} >
        <Img fluid={data.windmill.childImageSharp.fluid} />
      </div>
    }
  />

const SideMenu = ({ isOpen, toggleSideMenu }) => {
  return (
    <div className={classnames(styles.wrapper, { [styles.open]: isOpen })}>
      <WindmillIcon onClick={toggleSideMenu} />
      <NavLinks className={styles.linkList} />
    </div>
  )
}

export default SideMenu;