import React from 'react';
import { Link } from 'gatsby';

export default ({ className }) =>
  <ul className={className}>
    <li><Link to='/wedding'>Wedding</Link></li>
    <li><Link to='/accomodations'>Accomodations</Link></li>
    <li><Link to='/rsvp'>RSVP</Link></li>
    <li><a target="_blank" rel="noopener noreferrer" href='https://www.zola.com/registry/danielandsonya2019'>Registry</a></li>
  </ul>
  