import React from 'react'
import { graphql, Link, StaticQuery } from 'gatsby'
import Img from 'gatsby-image';
import NavLinks from './navLinks';

import styles from './nav-bar.module.scss';

// const HornGif = () => <img style={{maxHeight: '50px', marginBottom:'0'}}src="https://media.giphy.com/media/3oEhn2OSf8bRD5c9gI/200w_d.gif" />;
const BarnImage = () => (
  <StaticQuery
    query={graphql`
      query {
        barn: file(relativePath: { eq: "barn_blue.png" }) {
          childImageSharp {
            fluid(maxWidth: 350) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `}
    render={data => <Img fluid={data.barn.childImageSharp.fluid} />}
  />
)

const Header = ({ siteTitle, toggleSideMenu }) => (
  <nav className={styles.container}>
    <div className={styles.imageContainer}>
      <Link
        to="/"
        style={{
          textDecoration: 'none',
        }}
      >
        <BarnImage />
      </Link>
    </div>
    <h5 onClick={toggleSideMenu}>Menu</h5>
    <NavLinks className={styles.linkList}/>
  </nav>
)

export default Header
