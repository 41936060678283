import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from 'components/layout';
import styles from './index.module.scss';

const IndexPage = ({ data }) => (
  <Layout>
    <section className={styles.mainText}>
      <section className={styles.textContainer}>
        <h1 className={styles.header}>Sonya & Dan</h1>
        <h3 className={styles.subheader}>are getting married!!</h3>
        <Img className={styles.splash} fluid={data.splash.childImageSharp.fluid} />
        <h2 className={styles.date}>6.15.19</h2>
        <h3 className={styles.subheader}>Livingston Manor, NY</h3>
      </section>
    </section>
    <Img className={styles.chicken} fluid={data.chicken.childImageSharp.fluid} />
  </Layout>
)

export const query = graphql`
  query {
    splash: file(relativePath: { eq: "splash.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    chicken: file(relativePath: { eq: "chicken_bubble.png" }) {
      childImageSharp {
        fluid(maxWidth: 250) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

export default IndexPage
