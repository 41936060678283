import React, { useEffect, useRef, useState } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
// import { WindmillIcon } from 'components/SideMenu';

import styles from './loader.module.scss';

function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    let id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
}

const WindmillIcon = (props) => <StaticQuery
  query={graphql`
    query {
      windmill: file(relativePath: { eq: "windmill.png" }) {
        childImageSharp {
          fluid(maxWidth: 40) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `}
  render={data =>
    <div className={styles.iconContainer} {...props} >
      <Img fluid={data.windmill.childImageSharp.fluid} />
    </div>
  }
/>

const text = [
  'Sending RSVP',
  'Merci beaucoup',
  'Really kind of you',
  'Thanks so much',
  'Why is this taking so long 😅',
  'Maybe try again with more wifi'
];

const getNextText = (currentIndex) => {
  const nextIndex = currentIndex + 1 === text.length ? 0 : currentIndex + 1;
  return nextIndex;
};

export default () => {
  let [currentText, setText] = useState(0);
  
  useInterval(() => {
    setText(getNextText(currentText))
  }, 3000);

  return (
    <div className={styles.overlay}>
      <div className={styles.content}>
        <WindmillIcon />
        <h3 className={styles.text}>{`${text[currentText]}...`}</h3>
      </div>
    </div>
  );
}